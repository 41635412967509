<template>
  <b-button type="is-social" v-bind="$attrs" v-on="$listeners">
    <img :src="logo" />
  </b-button>
</template>

<script>
import GoogleLogo from '@/assets/social/google.png'
export default {
  data () {
    return {
      logo: GoogleLogo
    }
  }
}
</script>

<style>

</style>
